/* eslint-disable import/no-extraneous-dependencies */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { fetchPiiResource } from 'packages/apis';
import { Navbar } from 'shared-library';

type User = {
  firstname: string;
  lastname: string;
  roles: Array<string>;
};

type UserResponse = {
  user: User;
};

const initialUser = {
  firstname: 'Loading...',
  lastname: '',
  roles: ['user'],
};

const NavbarContainer = (): ReactElement => {
  const [user, setUser] = useState<User>(initialUser);

  const fetchCurrentUser = async () => {
    try {
      const response = await fetchPiiResource<UserResponse>('/api/v1/userV2/current');
      const { user: userResponse } = response.parsedBody;
      return userResponse;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  useEffect(() => {
    let mounted = true;
    fetchCurrentUser().then((userResponse) => {
      if (mounted) {
        setUser(userResponse);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  const handleLogoClick = useCallback(() => {
    window.location.href = '/';
  }, []);

  return <Navbar onLogoClick={handleLogoClick} user={user} />;
};

export default NavbarContainer;
