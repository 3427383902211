import React, { useContext, useEffect, useMemo, useState } from 'react';

import { getApolloClient} from 'shared-library';
import { getHpdBackendUrl } from 'packages/locations';
import { fetchPiiResource } from 'packages/apis';
import { ReportIssueButton } from 'packages/report-issue-button';
import NavbarContainer from '../Navbar';
import { RootContext } from '../RootContext';
import NpInformation from './NpInformation';
import { ApolloProvider } from '@apollo/client';

const NpHpdConnection: React.FC = () => {
  const { rootElement } = useContext(RootContext);
  const coordinator = rootElement.getAttribute('data-coordinator') === 'True';
  const selectedLocation = rootElement.getAttribute('data-location');
  const showNavbar = rootElement.getAttribute('data-navbar') === 'True';
  const showReportButton = rootElement.getAttribute('data-report-button') === 'True';

  const [apiEndpoint] = useState(getHpdBackendUrl());
  const [npIds, setNpIds] = useState([]);
  const initialize = async () => {
    if (coordinator) {
      const { parsedBody: npRoleUsers } = await fetchPiiResource(
        '/api/v1/users/query-role',
        'POST',
        {
          role: 'np',
          location: selectedLocation === 'can-bc-vancouver' ? 'can-bc-001' : selectedLocation,
        },
      );
      // eslint-disable-next-line camelcase
      return setNpIds(npRoleUsers.map(({ user_id }) => user_id));
    }
    if (rootElement.getAttribute('data-np-prenuvo-id')) {
      return setNpIds([rootElement.getAttribute('data-np-prenuvo-id')]);
    }
    return document.getElementById('np-hpd-connection').setAttribute('data-last', 'true');
  };

  useEffect(() => {
    initialize();
  }, []);

  const apolloClient = getApolloClient(apiEndpoint);

  return useMemo(() => {
    return (
      <>
        {apiEndpoint && (
          <ApolloProvider client={apolloClient}>
            {npIds.length ? <NpInformation idList={npIds} /> : null}
          </ApolloProvider>
        )}
        {showNavbar && <NavbarContainer />}
        {showReportButton && <ReportIssueButton />}
      </>
    );
  }, [npIds]);
};

export default NpHpdConnection;
