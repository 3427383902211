import React, { useEffect } from 'react';
import { practitionerQueries } from 'shared-library';
import { useLazyQuery } from '@apollo/client';

type PractitionerInformation = {
  id: string;
  firstName: string;
  lastName: string;
  locationId: string;
  prenuvoId: string;
};

const NpInformation = ({ idList }: { idList: string[] }): React.ReactElement => {
  const [getNpsFromHpd, { loading, error, data }] = useLazyQuery(
    practitionerQueries.PRACTITIONERS_BY_PRENUVO_IDS,
  );

  useEffect(() => {
    getNpsFromHpd({ variables: { practitionersList: idList } });
  }, []);

  if (error) {
    const npHpdConnection = document.getElementById('np-hpd-connection');
    npHpdConnection.setAttribute(`data-${idList[0].split('auth0|')[1]}`, JSON.stringify({}));
    return null;
  }

  if (!loading && !!data) {
    const formattedData: PractitionerInformation[] = data.practitionersByIds.edges
      .map((e: any) => e.node)
      .map((np: any) => {
        return {
          ...np,
          locationId: np?.locations?.edges[0]?.node?.id || null,
        };
      });

    formattedData.forEach((practitioner) => {
      if (practitioner.id) {
        const npHpdConnection = document.getElementById('np-hpd-connection');
        const npInfo = {
          prenuvoId: practitioner.prenuvoId,
          firstname: practitioner?.firstName,
          lastname: practitioner?.lastName,
          providerID: practitioner?.id,
          practitionerId: practitioner?.id,
          locationId: practitioner?.locationId,
        };

        npHpdConnection.setAttribute(
          `data-${practitioner.prenuvoId.split('auth0|')[1]}`,
          JSON.stringify(npInfo),
        );

        if (
          Object.keys(document.getElementById('np-hpd-connection').dataset).length ===
          formattedData.length
        ) {
          npHpdConnection.setAttribute('data-last', 'true');
        }
      }
    });
  }

  return null;
};

export default NpInformation;
